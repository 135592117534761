.admin_timer-card {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
}


.header_btn {
    display: flex;
    align-items: center;
}

.free {
    border: 3px solid #22ff98;
    border-radius: 8px;
}

.active_students {
    border: 3px solid #8a4848;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .timer_layout-full {
        background-color: red;
        padding: 20px;
    }
}

.timer_layout-full {
    width: 100%;
    padding: 20px;
}

.timer_layout-tv {
    width: 100%;
    padding: 20px;
}

.timer_layout-tv {
    display: grid;
    grid-gap: 15px;
    height: 100vh;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

@media only screen and (min-width: 1300px) {
    .timer_layout-full {
        display: grid;
        grid-gap: 15px;
        height: 100vh;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
    .timer_layout-full {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
    }

}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
    .timer_layout-full {
        display: grid;
        grid-gap: 15px;
        height: auto;
        grid-template-columns: 1fr 1fr;
    }

}

.lib_logo {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 1000px) {
    .lib_logo > img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: contain;
    }

    .lib_logo > span {
        color: white;
        margin-left: 10px;
        font-size: 14px;
        line-height: 1.3;
    }

    .btn_remove {
        color: white;
        border: 1px solid white;
        padding: 4px 10px;
        margin-left: 5px;

    }

    .card-body {
        margin-bottom: 20px;
    }

    .footer_list {
        display: flex;
        align-items: center;
    }

    .footer_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 1000px) {
    .lib_logo > img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: contain;
    }

    .lib_logo > span {
        color: white;
        margin-left: 10px;
        font-size: 22px;
    }

    .btn_remove {
        color: white;
        border: 1px solid white;
        padding: 6px 15px;
        margin-left: 15px;
        font-size: 34px;

    }

    .card-body {
        margin-bottom: 50px;
    }

    .footer_list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}












